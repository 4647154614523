import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import VuetifyToast from 'vuetify-toast-snackbar';
import router from '@/router';
import vuetify from '@/plugins/vuetify';
import VuetifyTelField from '@/modules/VuetifyTelField';
import App from '@/App.vue';
import i18n from './modules/i18n';
import store from './modules/store';
import { capitalize, formatDateLocaleDateString, spacedPhoneNumber, maskPhone } from '@/filters';
import '@/sass/app.scss';

export { i18n };
import './modules/plugins';
import './modules/globalComponents';

import * as Sentry from "@sentry/vue";
import environment from "@/environment";
import KeycloakDocline from '@/plugins/keycloak/keycloak'
import {
  authUserService,
  INACTIVE_USER_TIME_THRESHOLD_IN_MILLISECONDS,
  INACTIVE_USER_TIME_THRESHOLD_IN_MINUTES
} from "@/services/auth-user";
import {baseNameRoutes} from "@/router/paths";
import {KeycloakInitOptions} from "keycloak-js";

// sync the router with the vuex store
sync(store, router);

Vue.use(VuetifyTelField, {
  vuetify,
});

// VuetifyToast
Vue.use(VuetifyToast, {
  $vuetify: vuetify.framework,
  x: 'left',
  y: 'bottom',
  color: 'info',
  icon: false,
  timeout: 6000,
  dismissable: true,
  autoHeight: false,
  multiLine: false,
  vertical: false,
  shorts: {
    custom: {
      color: 'purple',
    },
  },
  property: '$toast',
});

// eslint-disable-next-line @typescript-eslint/no-var-requires
Vue.use(require('vue-shortkey'));

[capitalize, formatDateLocaleDateString, spacedPhoneNumber, maskPhone].forEach(filter => {
  Vue.filter(filter.name, filter);
});

Vue.config.productionTip = false;

function loadSentry() {
  if (environment.sentry) {
    Sentry.init({
      Vue,
      dsn: environment.sentry.dsn,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
}

loadSentry();

const loadVueWithKeycloakIntegration = function() {
  const initialConfig: KeycloakInitOptions = {
    onLoad: 'check-sso',
    checkLoginIframe: false,
    silentCheckSsoRedirectUri: window.location.origin + "/silent-sso.htm"
  };

  const currentAuthUserRaw = window.localStorage.getItem('auth_ehealth');
  const currentAuthUser = JSON.parse(currentAuthUserRaw);

  if (currentAuthUser?.access_token) {
    initialConfig.token = currentAuthUser.access_token;
  }

  if (currentAuthUser?.refresh_token) {
    initialConfig.refreshToken = currentAuthUser.refresh_token;
  }

  Vue.use(KeycloakDocline, {
    init: initialConfig,
    config: {
      url: environment.keycloakConfig.url,
      clientId: environment.keycloakConfig.clientId,
      realm: environment.keycloakConfig.realm
    },

    onReady (keycloak) {
      if (!keycloak.authenticated) {
        keycloak.login();
        return;
      }

      window.vm = new Vue({
        store,
        i18n,
        vuetify,
        router,
        render: h => h(App),
      }).$mount('#app');

      setInterval(() => {
        keycloak.updateToken(INACTIVE_USER_TIME_THRESHOLD_IN_MINUTES).then((refreshed)=>{
          if (refreshed) {
            authUserService.updateTokens(keycloak.token, keycloak.refreshToken);
          }
        }).catch(()=>{
          authUserService.remove();
          store.dispatch('app/loadConfigFromBrand');
          router.push({ name: baseNameRoutes.login });
        });

      }, INACTIVE_USER_TIME_THRESHOLD_IN_MILLISECONDS)
    }
  })
}

if (environment.isKeycloakIntegration) {
  loadVueWithKeycloakIntegration();
} else {
  window.vm = new Vue({
    store,
    i18n,
    vuetify,
    router,
    render: h => h(App),
  }).$mount('#app');
}
