<template>
  <div class="layout">
    <header>
      <app-menu :items="menuItems" />
      <app-toolbar class="app--toolbar" :show-docline-original-brand="showDoclineOriginalBrand" />
    </header>
    <v-main>
      <page-header v-if="$route.meta.breadcrumb" />
      <div class="page-wrapper">
        <slot />
        <the-footer v-if="showFooterApp" />
      </div>
    </v-main>
    <v-overlay :value="overlayStatus">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import environment from '@/environment';
import { getMenu } from '@/services/permissions';
import { TheFooter } from '@/common/components';
import AppMenu from '@/components/Layout/AppMenu/AppMenu.vue';
import AppToolbar from '@/components/Layout/AppToolbar.vue';
import PageHeader from '@/components/Layout/PageHeader.vue';

export default {
  components: {
    AppMenu,
    AppToolbar,
    PageHeader,
    TheFooter,
  },
  props: {
    showDoclineOriginalBrand: {
      type: Boolean,
      required: true,
    },
    showFooterApp: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menuItems: [],
      rgpd: environment.footer.rgpd,
    };
  },

  computed: {
    ...mapState({
      appDisplayName: state => state.app.displayName,
      appPermissions: state => state.app.permissions,
      companyPermissions: state => state.account.authUser.company.permissions,
      overlayStatus: state => state.overlay.showOverlay,
    }),
  },

  mounted() {
    this.menuItems = getMenu(this.appPermissions, this.companyPermissions);
  },
};
</script>

<style lang="scss">
// Not scope style, it must affect to other components
.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  footer {
    flex-shrink: 0;
  }
}
</style>

<style scoped>
.page-wrapper {
  min-height: calc(100vh - 64px - 50px - 81px);
}

.setting-fab {
  top: 50% !important;
  right: 0;
  border-radius: 0;
}
.rgpd a {
  padding-left: 10px;
}
</style>
