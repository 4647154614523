const getDomainWithoutSubdomain = (url: string) => {
  const uri = new URL(url);

  if (uri.hostname === 'localhost') {
    return "localhost";
  }

  const urlParts = uri.hostname.split('.');
  return `.${urlParts[urlParts.length - 2]}.${urlParts[urlParts.length - 1]}`;
}

export const remove = (field: string) => {
  console.log("REMOVING!")
  document.cookie = `${field}=;domain=${getDomainWithoutSubdomain(window.location.host)};path=/`;
};

export const get = (name: string) => {
  const cookieName = name + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(cookieName) === 0) {
      return c.substring(cookieName.length, c.length);
    }
  }
  return '';
};

const setItem = <T>(field: string, data: T, exp?: Date) => {
  const parseData = typeof data === 'string' ? data : JSON.stringify(data);
  document.cookie = `${field}=${parseData};${(exp) ? `expires=${exp.toUTCString()};` : ''}domain=${getDomainWithoutSubdomain(window.location.href)};path=/`;
};

export const cookiesService = {
    setItem
};
