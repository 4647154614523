export const COOKIES_KEYS = {
  PORTAL: "portalname",
  PORTAL_ACCESS: "portalaccess",
  PORTAL_URL: "portalurl",
  PORTAL_FRONT_URL: "portalfronturl",
  VIDEO_FRONT_URL: "videofronturl",
  PORTAL_LANGUAGE: "portallang",
  TENANT_ID: "tenantid",
  LAST_PATH: 'last_path',
};
